import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { MouseParallaxContainer, MouseParallaxChild } from 'react-parallax-mouse'

import { Content, PartnerSlider, OneOfServices, HeroGrid } from '../Atoms'
import { Bonuses, ServicesPanel, ValueStack, FourSteps, MakeMore } from '../Sections'
import Layout from '../Layout'

import { labels, localeUrls } from '../../langs/langs'

import bigI from '../../images/big-services.svg'

import gper from '../../images/grad-percentage.svg'
import garr from '../../images/grad-arrow.svg'
import geur from '../../images/grad-euro.svg'
import gcir from '../../images/grad-circle.svg'

const Services = ({ localeID, location }) => {
  return (
    <Layout location={location} title={labels[localeID]['SERVICES']} localeID={localeID}>
      <HeroGrid>
        <div className="hcenter">
          <div>
            <h1 className="text-5xl 2xl:text-6xl font-bold -tracking-[0.5px]">
              {labels[localeID]['KEY_PILLARS_3']}
            </h1>
            <p className="text-lg 2xl:text-xl font-medium py-10">
              {labels[localeID]['SERVICES_C']}
            </p>
            <Link to={localeUrls[localeID]['CTA']} className="button2">
              {labels[localeID]['WORK_W_US']}
            </Link>
          </div>
        </div>
        <div className="hidden sm:flex hcenter justify-center">
          <img className="w-3/4" src={bigI} />
        </div>
      </HeroGrid>
      <ServicesPanel localeID={localeID} />
      <ValueStack localeID={localeID} />
      <OneOfServices
        localeID={localeID}
        icon={gper}
        title={labels[localeID]['SER_1_F']}
        id={localeUrls[localeID]['SERVICES_TC']}
      >
        <p>{parse(labels[localeID]['SERV_1_P_1'])}</p>
        <p>{parse(labels[localeID]['SERV_1_P_2'])}</p>
        <p>{parse(labels[localeID]['SERV_1_P_3'])}</p>
        <p>{parse(labels[localeID]['SERV_1_P_4'])}</p>
        <p>{parse(labels[localeID]['SERV_1_P_5'])}</p>
        <p>{parse(labels[localeID]['SERV_1_P_6'])}</p>
        <p>{parse(labels[localeID]['SERV_1_P_7'])}</p>
        <p>{parse(labels[localeID]['SERV_1_P_8'])}</p>
      </OneOfServices>
      <OneOfServices
        localeID={localeID}
        icon={garr}
        title={labels[localeID]['SER_2']}
        gray={true}
        id={localeUrls[localeID]['SERVICES_CA']}
      >
        <p>{parse(labels[localeID]['SERV_2_P_1'])}</p>
        <p>{parse(labels[localeID]['SERV_2_P_2'])}</p>
        <p>{parse(labels[localeID]['SERV_2_P_3'])}</p>
        <p>{parse(labels[localeID]['SERV_2_P_4'])}</p>
        <p>{parse(labels[localeID]['SERV_2_P_5'])}</p>
        <p>{parse(labels[localeID]['SERV_2_P_6'])}</p>
      </OneOfServices>
      <OneOfServices
        localeID={localeID}
        icon={geur}
        title={labels[localeID]['SER_3']}
        id={localeUrls[localeID]['SERVICES_PA']}
      >
        <p>{parse(labels[localeID]['SERV_3_P_1'])}</p>
        <p>{parse(labels[localeID]['SERV_3_P_2'])}</p>
        <p>{parse(labels[localeID]['SERV_3_P_3'])}</p>
        <p>{parse(labels[localeID]['SERV_3_P_4'])}</p>
        <p>{parse(labels[localeID]['SERV_3_P_5'])}</p>
        <p>{parse(labels[localeID]['SERV_3_P_6'])}</p>
      </OneOfServices>
      <OneOfServices
        localeID={localeID}
        icon={gcir}
        title={labels[localeID]['SER_2_F']}
        gray={true}
        id={localeUrls[localeID]['SERVICES_AS']}
      >
        <p>{parse(labels[localeID]['SERV_4_P_1'])}</p>
        <p>{parse(labels[localeID]['SERV_4_P_2'])}</p>
        <p>{parse(labels[localeID]['SERV_4_P_3'])}</p>
        <p>{parse(labels[localeID]['SERV_4_P_4'])}</p>
        <p>{parse(labels[localeID]['SERV_4_P_5'])}</p>
        <p>{parse(labels[localeID]['SERV_4_P_6'])}</p>
        <p>{parse(labels[localeID]['SERV_4_P_7'])}</p>
      </OneOfServices>
      <Bonuses localeID={localeID} />
      <PartnerSlider />
      <FourSteps localeID={localeID} />
      <MakeMore localeID={localeID} />
    </Layout>
  )
}

export default Services
